<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="用户任务配置"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
       <template #header="params">
        <div class="search_box">
          <el-row :gutter="5" style="padding-top: 5px; padding-bottom: 5px">
            <el-col :span="2" style="text-align: end;">
              任务名称：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-input v-model="searchData['name']"  style="width: 200px" />
            </el-col>

            <el-col :span="2" style="text-align: end;">
              任务类型：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-select v-model="searchData['type']">
                <el-option
                    v-for="(item,index) in taskTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"/>
              </el-select>
            </el-col>
            <el-col :span="2" style="text-align: end;">
              任务进度：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-select v-model="searchData['progress']">
                <el-option
                    v-for="(item,index) in progressStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"></el-option>
              </el-select>
            </el-col>
          </el-row>

          <el-row :gutter="10" style="padding-top: 5px; padding-bottom: 5px">
            <el-col :span="2" style="text-align: end;">
              任务时间：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-date-picker
                  v-model="searchData['taskTimeRange']"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-col>

            <el-col :span="2" style="text-align: end;">
              任务创建时间：
            </el-col>
            <el-col :span="6" style="justify-content: left;">
              <el-date-picker
                  v-model="searchData['ctimeRange']"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="4" :offset="20">
              <el-button @click="resetSearchData">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>
      <template #navBar>
        <div class="table_nav_bar">
          <router-link to="/userTask/conf/add">
            <el-button type="primary">新建任务</el-button>
          </router-link>
        </div>
      </template>
      <el-table-column label="任务ID" fixed="left" prop="taskNo" :min-width="180">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="gotoDetail(scope.row.taskNo)">{{ scope.row.taskNo }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="任务名称" prop="name" :min-width="160"/>
      <el-table-column label="任务类型" prop="taskType" :min-width="160">
        <template slot-scope="scope">
          <div v-if="scope.row.type==1">新手任务</div>
          <div v-if="scope.row.type==2">日常任务</div>
        </template>
      </el-table-column>
      <el-table-column label="任务进度" prop="progress" :min-width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.progress==1">未开始</div>
          <div v-if="scope.row.progress==2">进行中</div>
          <div v-if="scope.row.progress==3">已结束</div>
        </template>
      </el-table-column>
      <el-table-column label="上架状态" prop="publishStatus" :min-width="150">
        <template slot-scope="scope">
          <div v-if="scope.row.publishStatus==1">未上架</div>
          <div v-if="scope.row.publishStatus==2">已上架</div>
          <div v-if="scope.row.publishStatus==3">已下架</div>
        </template>
      </el-table-column>
<!--      <el-table-column label="是否展示" prop="isShow" :min-width="150">-->
<!--        <template slot-scope="scope">-->
<!--          <div v-if="scope.row.isShow==1">展示</div>-->
<!--          <div v-if="scope.row.isShow==2">不展示</div>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="任务时间" prop="" :min-width="230">
        <template slot-scope="scope">
          <div>{{ scope.row.startTime }} - {{ scope.row.endTime }}</div>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="ctime" :min-width="180"/>

      <el-table-column label="操作" fixed="right" prop="" width="200">
        <template slot-scope="scope">
          <el-button class="handle_btn" type="text" size="mini" @click="tablePublishHandle(scope.row)" v-if="scope.row.publishStatus == 1 || scope.row.publishStatus == 3">上架</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tablePublishDownHandle(scope.row)" v-if="scope.row.publishStatus == 2">下架</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="editHandle(scope.row)" v-if="scope.row.publishStatus != 2">编辑</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableSortTopHandle(scope.row)">置顶</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(2, scope.row)">上移</el-button>
          <el-button class="handle_btn" type="text" size="mini" @click="tableHandle(3, scope.row)">下移</el-button>
        </template>
      </el-table-column>
    </my-table>
  </div>
</template>

<script>
/* eslint-disable */
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
    data() {
    //这里存放数据
      return {
        taskTypeList: [
            {
                label: "新手任务",
                value: 1
            },
            {
                label: "日常任务",
                value: 2
            }
        ],
        progressStatusList: [
            {
                label: "未开始",
                value: 1
            },
            {
                label: "进行中",
                value: 2
            },{
                label: "已结束",
                value: 3
            }
        ],
        listData: [],
        // 搜索数据
        searchData: {
          name: "", // 任务名称
          type: "", // 任务类型
          progress: "", // 任务进度
          ctimeStart: "", // 任务创建时间-开始
          ctimeEnd: "", // 任务创建时间-结束
          ctimeRange: "",
          startTime: "", // 任务运营时间-开始
          endTime: "", // 任务运营时间-结束
          taskTimeRange: '' //任务运营时间起止
        },
        listLoading: false //列表loading
      };
    },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num) {
      this.getTaskList(params, num ? num : params.pageNum, params.pageSize);
    },
    tableHandle(type, row) {
      this.taskNo = row.taskNo;
      let url;
      switch (type) {
        case 1:     //
          this.url = 'userTask/userTaskSortTop';
          break;
        case 2:
          this.url = 'userTask/userTaskSortUp';
          break;
        case 3:
          this.url = 'userTask/userTaskSortDown';
          break;
        default:
          return ;
          break;
      }
      this.listLoading = true;
      this.$store.dispatch(this.url, {
        taskNo: this.taskNo
      }).then(res => {
        if(res.code == 0){
          this.$message.success('操作成功')
          this.$refs.list.init()
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    tablePublishDownHandle(row) {
      this.taskNo = row.taskNo;
      let url = 'userTask/userTaskPublishDown';
      this.$confirm('是否确认下架？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true;
        this.$store.dispatch(url, {
          taskNo: this.taskNo
        }).then(res => {
          if(res.code == 0){
            this.$message.success('下架成功')
            this.$refs.list.init()
          }else{
            this.listLoading = false;
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消下架'
        });
      });
    },
    tablePublishHandle(row) {
      this.taskNo = row.taskNo;
      let url = 'userTask/userTaskPublish';
      this.$confirm('是否确认上架？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true;
        this.$store.dispatch(url, {
          taskNo: this.taskNo
        }).then(res => {
          if(res.code == 0){
            this.$message.success('上架成功')
            this.$refs.list.init()
          }else{
            this.listLoading = false;
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消上架'
        });
      });
    },
    tableSortTopHandle(row) {
      this.taskNo = row.taskNo;
      let url = 'userTask/userTaskSortTop';
      this.$confirm('是否确认置顶？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true;
        this.$store.dispatch(url, {
          taskNo: this.taskNo
        }).then(res => {
          if(res.code == 0){
            this.$message.success('置顶成功')
            this.$refs.list.init()
          }else{
            this.listLoading = false;
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消置顶'
        });
      });
    },
    editHandle(row) {
      this.$router.push(`/userTask/conf/edit/` + row.taskNo)
    },
    // 跳转详情
    gotoDetail(taskNo){
      this.$router.push(`/userTask/conf/view/` + taskNo)
    },

    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        name: "", // 任务名称
        type: "", // 任务类型
        progress: "", // 任务进度
        ctimeStart: "", // 任务创建时间-开始
        ctimeEnd: "", // 任务创建时间-结束
        ctimeRange: "",
        startTime: "", // 任务运营时间-开始
        endTime: "", // 任务运营时间-结束
        taskTimeRange: '' //任务运营时间起止
      }
    },
    // 获取任务列表
    getTaskList(params,pageNum,pageSize) {
      if (this.searchData.ctimeRange) {
        this.searchData.ctimeStart = this.searchData.ctimeRange[0];
        this.searchData.ctimeEnd = this.searchData.ctimeRange[1];
      }
      if (this.searchData.taskTimeRange) {
        this.searchData.startTime = this.searchData.taskTimeRange[0];
        this.searchData.endTime = this.searchData.taskTimeRange[1];
      }

      this.listLoading = true;
      this.$store.dispatch('userTask/userTaskList',{
        ...this.searchData,
        pageNum,
        pageSize
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box {
    padding: 0 20px;
    .search_box {
      background: #fff;
      padding: 20px;
      margin-top: 20px;
    }
    .table_nav_bar{
      width: 100%;
      background: #fff;
      padding: 20px 20px 0 20px;
      box-sizing: border-box;
    }
  }
  .handle_btn{
    padding: 0;
  }
  .channel_item{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    >.el-input:nth-child(1){
      width: 260px;
    }
  }
  .channel_item:last-child{
    margin: 0;
  }
  .popularize_list_qrcode{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .qrcode_img_src{
    position: absolute;
    top: -10000%;
    z-index: -10000;
  }
  ::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
    padding: 0 !important;
  }
  ::v-deep .el-table__body tbody tr td:first-of-type {
    padding-left: 0px !important;
  }
</style>
